<template>
    <a-card :bordered="false">
        <template slot="title">
        <a-row align="middle" justify="space-between" type="flex">
            <a-col :span="4">
            <span style="font-weight: bolder; font-size: 20px;">折线图</span>
            </a-col>
            <a-col :span="12">
            <a-form layout="inline">
                <a-form-item label="">
                <a-range-picker v-model="query.time" :allowClear="false"
                                @calendarChange="calendarChange"
                                @change="handleQueryDateRangeChange" @openChange="handleOpenChange"/>
                </a-form-item>
            </a-form>
            </a-col>
        </a-row>
        </template>

        <a-spin :spinning="loading">
            <div id="line" style="height: 300px;"></div>
        </a-spin>
  </a-card>
</template>

<script>
import {Line} from '@antv/g2plot';
import { queryStreetLineMapApi } from '@/api/home'
import moment from 'moment'
export default {
    data() {
        return{
            loading: false,
            query: {
                time: [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),moment().startOf('month').format("YYYY-MM-DD")]
            },
            line: null
        }
    },
    methods: {
        calendarChange(dates) {
            this.query.time = dates
        },
        handleOpenChange() {
            this.query.time = []
        },
        handleQueryDateRangeChange() {
            this.queryWeightLinemap()
        },
        queryWeightLinemap() {
            this.loading = true
            this.query.startTime = this.query.time[0]
            this.query.endTime = this.query.time[1]
            queryStreetLineMapApi({...this.query}).then(res => {
                if(this.line) {
                    this.line.update({
                        data: res.result
                    })
                    this.loading = false
                    return
                }
                this.line = new Line('line', {
                        data: res.result,
                        xField: 'day',
                        yField: 'weight',
                        yAxis: {
                            label: {
                            // 数值格式化为千分位
                            formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                            },
                        },
                        seriesField: 'trash',
                        color: ({ trash }) => {
                             return trash === '厨余垃圾' ? '#707070' : trash === '其他垃圾' ? '#0081ff' :  trash === '可回收物' ? '#39b54a' : '#d81e06';
                        },
                        });

                this.line.render();
                this.loading = false

            })
        }
    }
}
</script>