<template>
    <div>
        <p class="p-title">各小区采集情况:  共{{accessResult.total}}个小区，{{accessResult.hasData}}个小区有数据，{{accessResult.hasNoData}}个小区无数据</p>
        <a-table :columns="columns" :data-source="centerListData" :scroll="{ y: 300}">
            <span slot="otherPercent" slot-scope="text,record">
                {{record.otherPercent}}%
            </span>
            <span slot="kitchenWastePercent" slot-scope="text,record">
                {{record.kitchenWastePercent}}%
            </span>
            <span slot="action" slot-scope="text, record, index">
                <a @click="viewDetail(record, index)">查看详情</a>
            </span>
        </a-table>
        <house ref="house"/>
    </div>
</template>

<script>
import { queryStreetAccessApi } from '@/api/home'
import { queryCenterAnalyzeApi } from '@/api/center'
import house from './house'
export default {
  components: { house },
  name: 'overviewOfStreetCollect',
    props: {
        houseData: {
            type: Array,
            default: []
        },
        houseDataBak: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            columns: [
                {
                    title: '小区名称',
                    dataIndex: 'centerName',
                    key: 'centerName'
                },
                {
                    title: '采集总量(kg)',
                    dataIndex: 'total',
                    key: 'total'
                },
                {
                    title: '厨余垃圾(kg)',
                    dataIndex: 'kitchenWaste',
                    key: 'kitchenWaste'
                },
                {
                    title: '其他垃圾(kg)',
                    dataIndex: 'other',
                    key: 'other'
                },
                {
                    title: '可回收物(kg)',
                    dataIndex: 'recyclable',
                    key: 'recyclable'
                },
                {
                    title: '有害垃圾(kg)',
                    dataIndex: 'harmful',
                    key: 'harmful',
                },
                {
                    title: '厨余分出率',
                    dataIndex: 'kitchenWastePercent',
                    key: 'kitchenWastePercent',
                    scopedSlots: { customRender: 'kitchenWastePercent'}
                },
                {
                    title: '其他垃圾分出率',
                    dataIndex: 'otherPercent',
                    key: 'otherPercent',
                   scopedSlots: { customRender: 'otherPercent'}
                },
            ],
            accessResult: {
                total: 0,
                hasData: 0,
                hasNoData: 0
            },
            centerListData: []
        }
    },
    methods: {
        viewDetail(record, index){
            
        },
        queryCenterAnalyze(params) {
            queryCenterAnalyzeApi({page: 1, limit: 300, startTime: params.startTime, endTime: params.endTime}, this.$route.query.moduleCode).then(res => {
                this.centerListData = res.result.content
            })
        },
        queryStreetAccess(params) {
            queryStreetAccessApi({...params}, this.$route.query.moduleCode).then(res => {
                this.accessResult = res.result
            })
            this.queryCenterAnalyze(params)

        },

    }
}
</script>
<style lang="scss">
  .p-title{
    font-weight: bolder;
    font-size: larger;
  }
</style>