<template>
    <div>
        <p class="p-title">数据告警</p>
        <a-table :columns="columns" :data-source="alarmData" :pagination="pagination">

        </a-table>
    </div>
</template>

<script>
import { queryStreetAlarmApi } from '@/api/home'
export default {
    data() {
        return{
            alarmData: [],
            columns: [],
            params: {
                page: 1,
                limit: 10,
                startTime: '',
                endTime: ''
            },
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
        }
    },
    methods: {
        queryStreetAlarm(time) {
            if(time) {
                this.params.startTime = time[0]
                this.params.endTime = time[1]
                this.changePage(1, 10)
            }
            queryStreetAlarmApi({...this.params}, this.$route.query.moduleCode).then(res => {
                this.alarmData = res.result.content
                this.pagination.total = res.result.totalElements
            })
        },
        changePage(current, size){
            this.params.page = current
            this.params.limit = size
            this.queryStreetAlarm()
        },
    }
}
</script>

<style lang="scss">
  .p-title{
    font-weight: bolder;
    font-size: larger;
  }
</style>