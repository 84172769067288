<template>
    <div>
        <a-card :bordered="false" class="statistic-card">
            <a-row>
                <a-col :span="8">
                    <img alt="" class="icon" src="../../../../assets/icons/统计中心.png"/>
                </a-col>
                <a-col :span="16">
                    <p class="head">{{ typeData.typeName }}采集总量</p>
                    <p class="head-count">
                        {{ typeData.typeWeight > 100000 ? (typeData.typeWeight / 1000).toFixed(2) + 'T' : typeData.typeWeight + 'KG' }}</p>
                    <p class="subhead">{{ typeData.typeName }}分出率：<span class="subhead-count">{{
                            typeData.typePercent
                        }}%</span></p>
                </a-col>
            </a-row>
        </a-card>
    </div>

</template>
<script>
import {trashType} from '@/common/config'

export default {
    props: {
        typeData: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            trashType,
        }
    }
}
</script>
<style lang="scss">
.statistic-card {
  box-shadow: rgb(240, 242, 245) 2px 2px 5px 1px;
  //height: 120px;

  .icon {
    height: 80px;

  }

  .head {
    font-weight: bolder;
    font-size: larger;
    margin-bottom: 4px;
  }

  .head-count {
    font-size: larger;
    margin-bottom: 8px;
  }

  .subhead {
    margin-bottom: 0;

    .subhead-count {
      color: red;
    }
  }
}
</style>